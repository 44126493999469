<template>
    <section class="main-content-section">
        <div class="grid">

            <div class="col-8-12 push-2-12 sm-col-1-1 sm-push-0">
                <div class="form-container" v-if="order">
                    
                    <h1 class="page-title bold">{{selectedProducts}}</h1>
                    
                    <form @submit.prevent="submitForm()">
                        <div class="form-row border-bottom" v-for="product in order.lines" :key="product.id">
                            <div class="flex details align-center">
                                <figure>
                                    <img :src="productImage(product.id)" :alt="product[`name${selectedLanguage}`]">
                                </figure>
                                <div class="text">
                                    <h3>{{product[`name${selectedLanguage}`]}}</h3>

                                    <div class="flex justify-space-between">
                                        <span>{{ amountOfProducts }}</span>
                                        <div class="flex">
                                            <button @click="removeFromOrder(product.id)" type="button" class="btn btn-primary light-grey small">{{remove}}</button>
                                            <span>{{product.quantity}}</span>
                                        </div>
                                    </div>

                                    <br />
                                    
                                    <div class="flex justify-space-between">
                                        <span><strong>{{ totalStamps }}</strong></span>
                                        <span><strong>{{ totalPrice }}</strong></span>
                                    </div>
                                    
                                    <div v-for="variantList in variants" :key="variantList.id">
                                        <div v-if="variantList.id === product.id">
                                            <div v-for="(variant, i) in variantList.variants" :key="`variant-${i}`">     
                                                <div class="flex justify-space-between">
                                                    <span>{{ variant.stampAmount * product.quantity }} {{ stamps.toLowerCase() }}</span>
                                                    <span>{{variant.price * product.quantity === 0 ? free : (variant.price * product.quantity).toFixed(2) + ' &euro;' }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>


                        <div class="form-row flex flex-column">
                            <div class="total-order">
                                <h2 class="page-title">{{totalOrder}}</h2>
                                <div class="flex justify-space-between">
                                    <span>{{selectedProducts}}</span>
                                    <span>{{totalProductAmount}}</span>
                                </div>
                            </div>
                        </div>

                        <div class="form-row">
                            <span class="small">* {{indicatesRequiredField}}</span>
                        </div>

                        <div class="form-row flex justify-space-between align-center">
                            <router-link :to="`/product-catalog?orderId=${order.id}`" class="btn btn-secondary">{{backToProductCatalog}}</router-link>
                            <button type="submit" class="btn btn-primary alternative submitBtn">{{proceed}}</button>
                        </div>                      
                    </form>
                </div>

                <div v-else class="form-container no-results">
                    <div class="flex flex-column justify-center align-center">
                        <h1>{{yourCartIsEmpty}}</h1>
                        <p>{{noProductsInShoppingList}}</p>
                        <router-link class="btn btn-primary alternative" to="/product-catalog">{{continueShopping}}</router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';
import agent from '../../api/agent';

export default {
    name: 'OrderSummary',

    data() {
        return {
            voucher: '',
            showSummary: false,
            variants: []
        }
    },

    computed: {
        ...mapGetters([
            'selectedLanguage',
            'metaTitle',
            // Texts
            'selectedProducts',
            'amountOfProducts',
            'totalOrder',
            'indicatesRequiredField',
            'backToProductCatalog',
            'proceed',
            'isRequired',
            'isVoucherCodeRequired',
            'yourCartIsEmpty',
            'noProductsInShoppingList',
            'continueShopping',
            'remove',
            'totalStamps',
            'totalPrice',
            'stamps',
            'free'
        ]),
        ...mapGetters({
            order: 'getOrder'
        }),
        productImage() {
            return (id) => this.$store.getters.getProductById(id).imageUrl;
        },
        totalProductAmount() {
            return this.order.lines.length > 0 ? this.order.lines.map(line => line.quantity).reduce((a, b) => a + b) : 0;
        },
    },

    methods: {
        setDocumentTitle() {
            document.title = this.selectedProducts + this.metaTitle;
        },

        submitForm() {
            this.$router.push(`/place-reservation/select-store?orderId=${this.$route.query.orderId}`);
        },

        async removeFromOrder(productId) {
            try {
                const orderId = await agent.order.removeProduct({data: {orderId: this.order.id, productId: productId}});
                this.$store.dispatch('fetchOrder', orderId);
            } catch (error) {
                console.error(error);
            }
        },
    },

    async created() {
        this.setDocumentTitle();

        if (this.$route.query.orderId !== 'null') {
            this.showSummary = true;
        }

        await this.$store.dispatch('setOrderId', this.$route.query.orderId);
        await this.$store.dispatch('fetchOrder', this.$route.query.orderId);

        if (this.order) {
            this.order.lines.forEach(line => {
                const productName = line.name1;
                agent.products.all().then(products => {
                   const product = products.find(product => product.name1 === productName);
                   const variants = product.variants;
                   this.variants.push({
                        id: product.id,
                        variants: variants,
                    }); 
                });
            });
        }
    },

    updated() {
        this.setDocumentTitle();
    }
}
</script>

<style lang="scss" scoped>
@import '@/global-styles/variables';

.page-title {
    text-align: left;
}

.form-container {
    .form-row {        
        &.border-bottom {
            border-bottom: 1px solid $grey-light-7;

            #voucher {
                margin-bottom: 2rem;
            }
        }
    } 

    .details {
        margin-bottom: 2rem;

        figure {
            width: 20%;
    
            img {
                width: 100%;
                height: auto;
            }
        }
    
        .text {
            flex: 1;
            padding-left: 1rem;
            padding-right: 1rem;
        }
    }

    .total-order {
        background-color: $grey-light-7;
        border-radius: 0.8rem;
        width: 100%;
        padding: 2rem 3rem 4rem 3rem;
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

    a {
        text-decoration: none;
    
        &:hover {
            color: $white;
        }
    }

    .btn {
        min-width: 23rem;
        text-align: center;

        &.small {
            min-width: 0;
            margin-right: 2rem;
        }
    }

    &.no-results {
        h1 {
            margin-bottom: 2rem;
        }

        p {
            margin-bottom: 5rem;
        }
    }
}

@media only screen and (max-width: 950px) {
    .form-container .btn {
        min-width: 20rem;
        font-size: 1.6rem;
    }
}

@media only screen and (max-width: 880px) {
    .form-container .btn {
        min-width: 18rem;
        font-size: 1.4rem;
    }
}

@media only screen and (max-width: 530px) {
    .form-container .btn {
        min-width: 15rem;
        font-size: 1.2rem;
    }
}

@media only screen and (max-width: 420px) {
    .form-container .btn {
        min-width: 100%;
        font-size: 1.6rem;
    }

    .form-container a {
        margin-bottom: 2rem;
    }
}

@media only screen and (max-width: 330px) {
    .form-container .total-order {
        padding: 2rem 1rem;
    }
}

@media only screen and (max-width: 300px) {
    .form-container .btn {
        font-size: 1.4rem;
    }

    .form-container a {
        margin-bottom: 1rem;
    }
}
</style>